<template>
    <div class="freight-content">
        <el-breadcrumb separator=">" class="genera-breadcrumb" style="margin-left: 20px">
            <el-breadcrumb-item>网店管理</el-breadcrumb-item>
            <el-breadcrumb-item>运单模板</el-breadcrumb-item>
            <el-breadcrumb-item>添加运单模板</el-breadcrumb-item>
        </el-breadcrumb>
        <el-scrollbar class="freight-wrapper">
            <div class="freight-info">
                <div class="freight-title">
                    <span>新建单品运费模板</span>
                    <span style="color: #999;font-size: 14px;margin-left: 20px" v-if="goodsReleaseForm.free_radio===1">运费按照商品金额、优惠（直降/单品促销）返现之后的订单金额收取</span>
                </div>
                <div class="item">
                    <span class="text">模板名称</span>
                    <el-input v-model="goodsReleaseForm.freight_template_title" placeholder="请输入模板名称"
                              style="width: 550px;"></el-input>
                </div>
                <div class="item">
                    <span class="text">是否包邮</span>
                    <el-radio-group v-model="goodsReleaseForm.free_radio" @change="changeFreeRadio()">
                        <el-radio :label="0">买家承担运费</el-radio>
                        <el-radio :label="1">卖家承担运费</el-radio>
                    </el-radio-group>
                </div>
                <div class="item">
                    <span class="text">计费规则</span>
                    <el-radio-group v-model="goodsReleaseForm.charging_radio" @change="chargingRadio()">
                        <el-radio :label="0">按件数</el-radio>
                        <el-radio :label="1">按重量</el-radio>
                    </el-radio-group>
                </div>
                <div class="item">
                    <div class="top-title">
                        <p>除指定地区外，其余地区的运费采用"默认运费"</p>
                        <p>快递</p>
                    </div>
                    <div class="freight-box">
                        <div class="freight-top">
                            <span>国内默认运费</span>
                            <el-input-number v-model="goodsReleaseForm.piece" :min="0" :precision="0" :controls="false"
                                             style="width: 100px;margin:0 10px"></el-input-number>
                            {{goodsReleaseForm.ruleUnit}}内，
                            <el-input-number v-model="goodsReleaseForm.element" :min="0" :precision="0"
                                             :controls="false" style="width: 100px;margin:0 10px"></el-input-number>
                            元，
                            <span>每增加</span>
                            <el-input-number v-model="goodsReleaseForm.add_num" :min="0" :precision="0"
                                             :controls="false" style="width: 100px;margin:0 10px"></el-input-number>
                            {{goodsReleaseForm.ruleUnit}}，
                            <span>增加</span>
                            <el-input-number v-model="goodsReleaseForm.add_price" :min="0" :precision="0"
                                             :controls="false" style="width: 100px;margin:0 10px"></el-input-number>
                            元
                        </div>
                        <el-table :data="freightTable" style="width: 100%; flex: 1" size="medium" border
                                  v-if="freightTable.length >0"
                                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
                                  :cell-style="{fontSize: '12px',color: '#333',height: '70px'}"
                                  class="customTable">
                            <el-table-column prop="transport" label="运送到" width="520px">
                                <template slot-scope="scope">
                                    <div class="region-item">
                                        <div style="flex: 1">{{scope.row.city}}</div>
                                        <span @click="editRegion(scope.row.city,scope.$index)">编辑</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="first_thing" label="（首价）个" align="center">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.first_thing" :min="0" :precision="0"
                                                     :controls="false" style="width: 80px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column prop="first_cost" label="（首费）元" align="center">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.first_cost" :min="0" :precision="0"
                                                     :controls="false" style="width: 80px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column prop="add_thing" label="（续件）个" align="center">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.add_thing" :min="0" :precision="0"
                                                     :controls="false" style="width: 80px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column prop="add_cost" label="（续费）元" align="center">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.add_cost" :min="0" :precision="0"
                                                     :controls="false" style="width: 80px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="link-list">
                                        <el-link type="danger" :underline="false" @click="deleteFreight(scope.$index)">
                                            删除
                                        </el-link>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="add-freight" @click="addRegion()">+为指定地区城市设置运费</div>
                    </div>
                </div>
            </div>
            <div class="dialog-footer">
                <el-button @click="backBtn()">返回</el-button>
                <el-button type="primary" @click="saveAreaBtn()">保存</el-button>
            </div>
        </el-scrollbar>
        <!--地区弹窗-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="addRegionTitle"
                   custom-class="green-dialog" :visible.sync="addressDialogVisible" width="650px">
            <el-scrollbar class="pop-up">
                <div class="area-box">
                    <el-checkbox v-model="checkedAllChina" @change="checkedAllChinaBtn">国内</el-checkbox>
                    <div class="area-list">
                        <div v-for="(item,index) in areaArr" :key="index" class="checkbox-label">
                            <el-checkbox v-model="item.checked" @change="checked=>areaChange(checked,index)">
                                {{item.name}}
                            </el-checkbox>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
            <div class="dialog-footer">
                <el-button style="margin-left: 30px" type="primary" @click="addCityBtn()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {stuOpSaveFreightTemplate,stuOpStuOpFreightTemplate} from '@/utils/apis'
    import {areaList} from '@/utils/area'

    export default {
        name: "StudentFreight",
        data() {
            return {
                examModuleId: Number(this.$route.query.exam_module_id) || '',
                goodsReleaseForm: {
                    goods_number_score: void 0,//商品货号分值设置
                    freight_template_title: '',//模板名称
                    free_radio: 0,//是否包邮
                    charging_radio: 0,//计费规则
                    piece: void 0,//
                    element: void 0,
                    add_num: void 0,//
                    add_price: void 0,
                    ruleUnit: '件'//规则单位
                },
                //运费设置
                freightTable: [],
                freightTableIndex: -1,
                // 地区
                addRegionTitle: '',//地区弹窗标题
                addressDialogVisible: false,
                checkAll: false,
                checkedCities: [],
                // cityOptions: [],
                areaArr: [],
                isIndeterminate: true,//区显示隐藏
                checkedAllChina: false,//国内变量
            }
        },
        mounted() {
            //循环地区列表
            for (let i in areaList) {
                let ob = {
                    checked: false,
                    name: areaList[i].name
                }
                this.areaArr.push(ob)
            }
            //获取商品详情
            if (this.$route.query.t_id){
                this.getTemplate()
            }
        },
        methods: {
            //获取模板详情
            getTemplate(){
                let params = {}
                if (this.$route.query.id){
                    params.op_id = this.$route.query.id
                }
                if (this.$route.query.course_id){
                    params.course_id = this.$route.query.course_id
                }
                stuOpStuOpFreightTemplate(params).then((res) => {
                    this.goodsReleaseForm.freight_template_title = res.data.freight_template_title;
                    this.goodsReleaseForm.free_radio = res.data.is_exempt_postage;
                    this.goodsReleaseForm.charging_radio = res.data.accounting_rules;
                    this.goodsReleaseForm.piece = res.data.default_num;
                    this.goodsReleaseForm.element = res.data.default_price;
                    this.goodsReleaseForm.add_num = res.data.add_num;
                    this.goodsReleaseForm.add_price = res.data.add_price;
                    this.freightTable = res.data.freight_setting;
                }).catch((err) => {
                    console.error('err', err);
                });
            },
            //是否包邮点击事件
            changeFreeRadio() {
                /*if (this.goodsReleaseForm.free_radio === 1) {
                    this.$confirm(`选择“卖家承担运费”后，所有区域的运费将设置为0元 且原运费设置无法恢复`, '提示消息', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        customClass: 'green-message-box',
                    }).then(() => {
                        this.$message({
                            message: ('切换成功'),
                            type: 'success',
                        })
                    }).catch((cancel) => {
                        this.goodsReleaseForm.free_radio = 0;
                        this.$message({
                            type: 'info',
                            message: '已取消'
                        });
                    });
                }*/
            },
            //计费规则
            chargingRadio() {
                this.$confirm(`确定切换计费规则吗？，切换后，当前所设置的运费信息将被清空`, '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                }).then(() => {
                    this.$message({
                        message: ('切换成功'),
                        type: 'success',
                    })
                    if (this.goodsReleaseForm.charging_radio === 0) {
                        this.goodsReleaseForm.ruleUnit = '件'
                    } else {
                        this.goodsReleaseForm.ruleUnit = 'kg'
                    }
                }).catch(() => {
                    if (this.goodsReleaseForm.charging_radio === 0) {
                        this.goodsReleaseForm.charging_radio = 1;
                    } else {
                        this.goodsReleaseForm.charging_radio = 0;
                    }
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            //地区选择
            addRegion() {
                this.addRegionTitle = '选择地区'
                this.addressDialogVisible = true
            },
            // 编辑地区
            editRegion(city, index) {
                this.freightTableIndex = index;
                this.addRegionTitle = '编辑地区'
                this.addressDialogVisible = true;
                let arr = city.split(" ");
                for (let i in this.areaArr) {
                    if (arr.includes(this.areaArr[i].name)) {
                        this.areaArr[i].checked = true;
                    } else {
                        this.areaArr[i].checked = false;
                    }
                    /*for (let k in arr) {
                        if (arr[k] === this.areaArr[i].name) {
                            this.areaArr[i].checked = true;
                        } else {
                            this.areaArr[i].checked = false;
                        }
                    }*/
                }
                let isAll = true;
                for (let i in this.areaArr) {
                    if (!this.areaArr[i].checked) {
                        isAll = false;
                        break;
                    }
                }
                this.checkedAllChina = isAll
            },
            // 全国
            checkedAllChinaBtn(val) {
                this.checkedAllChina = val;
                for (let i in this.areaArr) {
                    this.areaArr[i].checked = this.checkedAllChina;
                }
            },
            areaChange(e, index) {
                this.areaArr[index].checked = e;
                let isAll = true;
                for (let i in this.areaArr) {
                    if (!this.areaArr[i].checked) {
                        isAll = false;
                        break;
                    }
                }
                this.checkedAllChina = isAll;
            },
            // 地区确定事件
            addCityBtn() {
                let arr = [];
                for (let i in this.areaArr) {
                    if (this.areaArr[i].checked) {
                        arr = arr.concat(this.areaArr[i].name);
                    }
                }
                if (this.freightTableIndex > -1) {
                    this.freightTable[this.freightTableIndex].city = arr.join(' ');
                } else {
                    let ob = {
                        city: arr.join(' '),
                        first_thing: void 0,
                        first_cost: void 0,
                        add_thing: void 0,
                        add_cost: void 0,
                    }
                    this.freightTable.push(ob)
                }
                this.addressDialogVisible = false;
                for (let i in this.areaArr) {
                    this.areaArr[i].checked = false;
                }
                this.checkedAllChina = false;
                this.freightTableIndex = -1;
            },
            //运单删除
            deleteFreight(index) {
                this.freightTable.splice(index, 1);
            },
            // 返回
            backBtn(){
                if (this.$route.query.id){
                    this.$router.push({
                        path: '/student/trainCenter/onlineShopManagement/freightTemplate',
                        query:{
                            exam_module_id:this.examModuleId,
                            id:this.$route.query.id,
                            course_id:this.$route.query.course_id,
                        }
                    })
                }else {
                    this.$router.push({
                        path: '/student/examing/onlineShopManagement/freightTemplate',
                        query:{
                            exam_module_id:this.examModuleId,
                        }
                    })
                }
            },
            //保存
            saveAreaBtn() {
                let param = {
                    freight_template_title: this.goodsReleaseForm.freight_template_title,
                    is_exempt_postage: this.goodsReleaseForm.free_radio,
                    accounting_rules: this.goodsReleaseForm.charging_radio,
                    default_num: this.goodsReleaseForm.piece,
                    default_price: this.goodsReleaseForm.element,
                    add_num: this.goodsReleaseForm.add_num,
                    add_price: this.goodsReleaseForm.add_price,
                    freight_setting: this.freightTable,
                }
                if (this.$route.query.id){
                    param.op_id=this.$route.query.id
                }
                if (this.$route.query.course_id){
                    param.course_id=this.$route.query.course_id
                }
                stuOpSaveFreightTemplate(param).then((res) => {
                    this.$message.success(res.msg)
                    if (this.$route.query.id){
                        this.$router.push({
                            path: '/student/trainCenter/onlineShopManagement/freightTemplate',
                            query:{
                                exam_module_id:this.examModuleId,
                                id:this.$route.query.id,
                                course_id:this.$route.query.course_id,
                            }
                        })
                    }else {
                        this.$router.push({
                            path: '/student/examing/onlineShopManagement/freightTemplate',
                            query:{
                                exam_module_id:this.examModuleId,
                            }
                        })
                    }
                }).catch((err) => {
                    console.error('err', err);
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .freight-wrapper {
        height: 100%;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .dialog-footer {
            text-align: center;
        }
    }

    .freight-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        .freight-info {
            padding: 0 20px;
            height: calc(100vh - 220px);

            .freight-title {
                font-size: 20px;
                padding-left: 15px;
                line-height: 1;
                margin: 20px 0 30px;
                position: relative;
                display: flex;
                align-items: center;
                /*justify-content: space-between;*/
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 5px;
                    height: 20px;
                    background: #2DC079;
                    border-radius: 3px;
                    transform: translateY(-50%);
                }
            }

            .item {
                margin-bottom: 16px;
                padding-left: 78px;

                .text {
                    padding-right: 12px;
                    display: inline-block;
                    min-width: 82px;
                }

                .freight-box {
                    border: 1px solid #eee;

                    .freight-top {
                        background: #F6F6F6;
                        padding: 20px 47px;
                    }

                    .region-item {
                        display: flex;

                        span {
                            display: inline-block;
                            width: 40px;
                            color: #2461EF;
                            padding-left: 20px;
                            align-self: center;
                            cursor: pointer;
                        }
                    }

                    .add-freight {
                        cursor: pointer;
                        padding: 20px;
                        color: #2461EF;
                    }
                }

                .freight-title {
                    margin-bottom: 15px;
                }

                .material-upload-area {
                    display: flex;
                    align-items: center;

                    .el-upload-tip {
                        color: #999;
                        padding-left: 10px;
                    }

                    ::v-deep .el-upload-list {
                        display: flex;
                        align-items: center;
                    }
                }

                ::v-deep .el-upload-list {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }

                ::v-deep .el-upload-list__item {
                    width: 300px;
                    padding-left: 10px;
                }
            }
        }

        .pop-up {
            height:300px;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .area-box {
                display: flex;

                .area-list {
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 45px;

                    .checkbox-label {
                        width: 104px;
                        margin-bottom: 10px;

                        i {
                            cursor: pointer;
                        }
                    }
                }

                .checkbox-group {
                    margin: 10px 0;

                    ::v-deep .el-checkbox-group {
                        display: flex;
                        flex-wrap: wrap;
                        /*width: 100px;*/
                        /*background-color: #fff;*/
                        /*box-shadow: 2px 2px 2px 3px #eee;*/
                        padding-left: 10px;
                    }

                    /*::v-deep .el-checkbox {*/
                    /*    width: 58px;*/
                    /*}*/
                }
            }
        }

    }

</style>